import React, { ReactNode, useEffect, useMemo } from 'react';
import { useAppConfig } from '@rugby-au/app-config';
import { getTheme, IColorScale, ThemeProvider } from '@rugby-au/theme';
import { WebHeader } from '@rugby-au/web-header';

export type PageLayout = {
  siteColors: {
    [key: string]: IColorScale;
  };
  includeHeader?: boolean;
  logo?: string;
};
export const PageLayout = ({ children, siteColors, logo, includeHeader = true }: { children: ReactNode } & PageLayout) => {
  const { header, setHeader } = useAppConfig();

  useEffect(() => {
    if (header && logo && logo !== '' && header.siteLogo?.url !== logo) {
      setHeader && setHeader({ ...header, siteLogo: { ...header.siteLogo, url: logo, alt: header.siteLogo?.alt ?? '' } });
    }
  }, [header, logo, setHeader]);

  const theme = useMemo(() => getTheme({ siteColors }), [siteColors]);
  return (
    <ThemeProvider theme={theme}>
      {includeHeader ? <WebHeader {...header} /> : null}
      {children}
    </ThemeProvider>
  );
};
