import React, { useRef, useEffect } from 'react';
import { SamlFormProps } from './type';

export const SamlForm = ({ target, samlResponse }: SamlFormProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const samlReponseinputRef = useRef<HTMLInputElement>(null);
  const relayStateinputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (samlReponseinputRef.current) {
      samlReponseinputRef.current.value = samlResponse.response as string;
    }
    if (relayStateinputRef.current) {
      relayStateinputRef.current.value = samlResponse.relayState as string;
    }
    formRef.current?.submit();
  }, [samlResponse]);

  //.replace(/[=]/g, '%3D');
  return (
    <div>
      <form ref={formRef} id="samlResponseForm" method="POST" target={target} action={samlResponse.destination}>
        <input ref={samlReponseinputRef} id="samlResponse" type="hidden" name="SAMLResponse" />
        <input ref={relayStateinputRef} id="RelayState" type="hidden" name="RelayState" />
      </form>
    </div>
  );
};
