import { IColorGroup, ISiteColors, ISpacing, ITypography } from '@rugby-au/theme';
import { StyleSheet, TextStyle } from 'react-native';

export const getAccountDetailsStyles = () => {
  return StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 22,
    },
    modalView: {
      margin: 20,
      backgroundColor: 'white',
      borderRadius: 20,
      padding: 35,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      //width: 350
    },
    textStyle: {
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    modalHeader: {
      marginBottom: 15,
      textAlign: 'center',
    },
    modalText: {
      marginBottom: 15,
      textAlign: 'center',
    },
    errorText: {
      color: '#E11900',
      textAlign: 'center',
    },
    modalBackgroundStyles: {
      backgroundColor: 'rgba(0,0,0,0.4)',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
  });
};
export const getEmailSentStyles = ({ colors, typography, spacing, headings }: { colors: IColorGroup; typography: ITypography; spacing: ISpacing; headings: any }) => {
  return StyleSheet.create({
    headerTextStyles: {
      textAlign: 'center',
      color: colors.background.mid,
      fontSize: typography.fontSize['3xlarge'],
      '@media (min-width: 768px)': { fontSize: typography.fontSize['5xlarge'] },
      '@media (min-width: 992px)': { fontSize: typography.fontSize['6xlarge'] },
      fontFamily: typography.fontFamily.bold,
    } as TextStyle,
    messageText: {
      textAlign: 'center',
      ...headings[5],
      fontFamily: typography.fontFamily.regular,
      marginTop: spacing.xsmall,
      marginBottom: spacing.xsmall,
      color: colors.neutral.dark,
    } as TextStyle,
    emailText: {
      textAlign: 'center',
      fontFamily: typography.fontFamily.semiBold,
      fontSize: typography.fontSize.large,
      marginTop: spacing.xsmall,
      marginBottom: spacing.xsmall,
      color: colors.neutral.dark,
    } as TextStyle,
  });
};

export const getPasswordStyles = ({ colors }: { colors: ISiteColors }) => {
  return StyleSheet.create({
    error: { color: colors.danger, alignSelf: 'center' },
  });
};
