import React from 'react';
import { View } from 'react-native';

import { ITheme, useTheme } from '@rugby-au/theme';
import { Text } from '@rugby-au/commons';

import { defaultTitleMaxLength, defaultDescriptionMaxLength, defaultInfoMaxLength } from './utils';

import { getCardContentStyles, TitleStyles, DescriptionStyles, InfoStyles } from './styles';

export interface CardContentProps {
  appearance?: ITheme;
  /** Title  */
  title: string;
  /** title max lenght */
  titleMaxLength?: number;
  /** Description */
  description?: Array<string>;
  /** description max lenght */
  descriptionMaxLength?: number;
  /** additional details  */
  info?: string;
  /** info max lenght */
  infoMaxLength?: number;
  /** custom styles */
  titleStyles?: TitleStyles;
  descriptionStyles?: DescriptionStyles;
  infoStyles?: InfoStyles;
}

export const CardContent = ({
  appearance = 'primary',
  title,
  titleMaxLength = defaultTitleMaxLength,
  description,
  descriptionMaxLength = defaultDescriptionMaxLength,
  info,
  infoMaxLength = defaultInfoMaxLength,
  titleStyles,
  descriptionStyles,
  infoStyles,
}: CardContentProps) => {
  const { colorPairs, spacing, headings, typography } = useTheme();
  const colors = colorPairs[appearance] ?? colorPairs.primary;
  const styles = getCardContentStyles({
    colors,
    spacing,
    headings,
    typography,
  });
  if (title.length > titleMaxLength) {
    title = title.substring(0, titleMaxLength) + '...';
  }
  let _description = description?.map(item => {
    if (item && item.length > descriptionMaxLength) {
      item = item.substring(0, descriptionMaxLength) + '...';
    }
    return item;
  });

  if (info && info.length > infoMaxLength) {
    info = info.substring(0, infoMaxLength) + '...';
  }
  return (
    <View style={styles.container}>
      {title && <Text style={{ ...styles.headerText, ...titleStyles }}>{title}</Text>}
      {_description && (
        <>
          {_description.map((desc, index) => (
            <Text key={index + desc} style={{ ...styles.subTitle, ...descriptionStyles }}>
              {desc}
            </Text>
          ))}
        </>
      )}
      {/* <FlatList
          data={_description}
          renderItem={({ item }) => <Text key={item.toString()} style={{ ...styles.subTitle, ...descriptionStyles }}>{item}</Text>}
          keyExtractor={(item, index) => `descIndex${index}_${item}`}
        />
      )} */}
      {info && <Text style={{ ...styles.info, ...infoStyles }}>{info}</Text>}
    </View>
  );
};
