import { FormConfigProps } from '@rugby-au/form';

export const resetPasswordFormConfig: FormConfigProps = {
  meta: {
    name: 'Set Password',
    title: 'Set Password',
    submitPath: 'rau/auth/v2/profile/reset/',
    submitMethod: 'POST',
    submitButton: { title: 'Confirm', appearance: 'primary', fullWidth: true, weight: 'bold' },
    requestParams: { encodedParams: ['password'] },
  },
  fields: [
    { key: 'password', label: 'Password', required: true, displayType: 'TextInput', type: 'password' },
    { key: 'confirmPassword', label: 'Re-Enter Password', required: true, displayType: 'TextInput', type: 'password' },
  ],
  // nationalConfig: {
  //   RAU: {},
  //   USA: {},
  // },
};
