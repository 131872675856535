import React, { useCallback } from 'react';
import { View } from 'react-native';

import { Image, RxImageProps } from '@rugby-au/image';
import { ITheme, useTheme } from '@rugby-au/theme';
import { Icons } from '@rugby-au/icons';
import { Text } from '@rugby-au/commons';

import { AvatarSize, getAvatarStyles } from './styles';

export interface AvatarProps {
  /** Button appearance */
  appearance?: ITheme;
  /** Size of the avatar */
  size?: AvatarSize;
  /** Avatar image source */
  image?: RxImageProps;
  /** Avatar name */
  name?: string;
  /** Background color: gives the options to override the background theme color. i.e team colors */
  backgroundColor?: string;
  /** Foreground color: gives the options to override the foreground theme color. i.e team colors */
  foregroundColor?: string;
  /** Add a border around the avatar */
  addBorder?: boolean;
}

export const Avatar = ({ appearance = 'primary', image, name, size = 'large', backgroundColor, foregroundColor, addBorder }: AvatarProps) => {
  const { colorPairs, spacing, sizes, radius } = useTheme();

  const styles = getAvatarStyles({
    colors: colorPairs[appearance],
    spacing,
    radius,
    backgroundColor: backgroundColor ?? colorPairs[appearance].background.mid,
    foregroundColor: foregroundColor ?? colorPairs[appearance].foreground.mid,
    size: sizes[size] ?? sizes.large,
    addBorder,
  });

  const getInitials = useCallback(() => {
    if (name) {
      const namesArray = name.split(' ');
      if (namesArray.length === 1) {
        return `${namesArray[0].charAt(0)}`;
      } else {
        return `${namesArray[0].charAt(0).toUpperCase()}${namesArray[namesArray.length - 1].charAt(0).toUpperCase()}`;
      }
    }
    return '';
  }, [name]);

  let container = <></>;

  if (image && image.url && image.url.length > 0) {
    //Avatar with image
    container = (
      <View style={styles.container}>
        <Image style={styles.image} {...image} />
      </View>
    );
  } else if (name) {
    //avatar with initials
    container = (
      <View style={styles.container}>
        <Text style={styles.text}>{getInitials()}</Text>
      </View>
    );
  } else {
    //avatar with user icon
    container = (
      <View style={styles.container}>
        <Icons
          type={'user'}
          height={sizes?.[size]?.boxSize - sizes?.[size]?.paddingY}
          width={sizes?.[size]?.boxSize - sizes?.[size]?.paddingX}
          color={foregroundColor ?? colorPairs[appearance].foreground.mid}
        />
      </View>
    );
  }
  return container;
};
