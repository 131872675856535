import { MutableRefObject, createRef, useCallback, useEffect, useMemo, useState } from 'react';

import { FieldRefMethodProps } from '@rugby-au/form-fields';
import { getItem, mfaResend, mfaVerify } from '@rugby-au/services';
import { useAppConfig } from '@rugby-au/app-config';
import { MFALayoutRefProp } from './MultiFactorAuthLayout';

const MFA_CODE_LENGTH = 6;
export const useMultiFactorAuth = ({
  layoutRef,
  type,
  expirationInMinutes,
  onSuccess,
}: {
  type: string;
  onSuccess: (response: any) => void;
  layoutRef: MutableRefObject<MFALayoutRefProp | null>;
  expirationInMinutes: boolean | number;
}) => {
  const { setNotification } = useAppConfig();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fieldRefs = useMemo(() => {
    return [...Array(MFA_CODE_LENGTH)].map(item => ({ key: item, ref: createRef<FieldRefMethodProps>() }));
  }, []);

  useEffect(() => {
    if (layoutRef) {
      layoutRef.current = {
        handleMfaVerify: handleMfaVerify,
      };
    }
  });

  const handleMfaVerify = useCallback(
    async ({ _code }: { _code?: string }) => {
      try {
        setLoading(true);
        setErrorMessage('');
        //get code from all fields
        const code = _code ? _code : fieldRefs.map(field => field.ref.current?.value).join('');
        //get token from local storage
        const token = localStorage.getItem('m-u');
        if (code.length !== MFA_CODE_LENGTH || !token) {
          setNotification({ severity: 'error', message: 'Invalid request' });
          return;
        }
        //Call mfa verify
        const client = await getItem('client');
        // Add error log to Sentry/trace issue with undefined reading SAMlRequest, this shouldn't happen
        if (!client) {
          //check url in sentry logs to check if clientid is comming in url
          console.error('Client not found');
        }
        const response = await mfaVerify({
          code,
          token,
          type,
          clientId: client?.username,
          codeChallenge: client?.codeChallenge,
          expirationInMinutes,
          samlRequest: client?.SAMLRequest,
        });

        if (!response.error) {
          //If success then do onFinish logic
          onSuccess(response);
          localStorage.removeItem('m-u');
        } else {
          //If error then show error message
          const msg = response?.error?.originalError?.message ?? response?.error?.message ?? 'Internal Sever Error';
          setErrorMessage(msg);
          setLoading(false);
        }
      } catch (error) {
        setErrorMessage((error as any).message ?? 'Internal Sever Error');
        setLoading(false);
      } finally {
        // setLoading(false);
      }
    },
    [expirationInMinutes, fieldRefs, onSuccess, setNotification, type],
  );

  const handleResendMfa = useCallback(async () => {
    try {
      setLoading(true);
      setErrorMessage('');
      //get token from local storage
      const token = localStorage.getItem('m-u');
      if (!token) {
        setNotification({ severity: 'error', message: 'Invalid request' });
        return;
      }
      const response = await mfaResend({ token });
      // update token
      if (response.token) {
        localStorage.setItem('m-u', response.token);
      } else if (response.error) {
        //If error then show error message
        const msg = response.error?.originalError?.message ?? response.error.message ?? 'Internal Sever Error';
        setErrorMessage(msg);
      }

      //call render mfa
    } catch (error) {
      const msg = (error as any).message ?? 'Internal Sever Error';
      setErrorMessage(msg);
      setNotification({ severity: 'error', message: msg });
    } finally {
      setLoading(false);
    }
  }, [setNotification]);

  useEffect(() => {
    const ac = new AbortController();
    const handleOTP = async () => {
      if (window.navigator) {
        // Cancel the WebOTP API if the form is submitted manually.
        // const ac = new AbortController();
        if (loading) {
          ac.abort();
        }

        // Invoke the WebOTP API
        try {
          const otp = (await (window.navigator.credentials as any).get({
            otp: { transport: ['sms'] },
            signal: ac.signal,
          })) as any;
          if (otp && otp.code && otp.code.length === MFA_CODE_LENGTH) {
            fieldRefs.forEach((_field: any, _index: any) => {
              _field.ref.current?.setValue && _field.ref.current?.setValue(otp.code[_index]);
            });
            handleMfaVerify({ _code: otp.code });
          }
        } catch (err) {
          console.log(err);
          ac.abort();
        }
      }
    };

    handleOTP();

    return () => {
      ac.abort();
    };
  }, [fieldRefs, handleMfaVerify, loading]);

  return { fieldRefs, handleMfaVerify, handleResendMfa, loading, errorMessage };
};
