import { IBreakPoints, IRadius, ISiteColors, ISpacing, ITypography } from '@rugby-au/theme';
import { Dimensions } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const getSignInColumnStyles = ({
  breakpoints,
  spacing,
  typography,
  radius,
  colors,
}: {
  breakpoints: IBreakPoints;
  spacing: ISpacing;
  typography: ITypography;
  radius: IRadius;
  colors: ISiteColors;
}) => {
  return StyleSheet.create({
    imageColumnStyles: {
      width: '0%',
      overflow: 'hidden',
      display: 'none',
      [`@media (min-width: ${breakpoints.large}px)`]: { width: '40%', overflow: 'hidden', display: 'flex' },
    },
    columnBaseStyles: { justifyContent: 'center', alignItems: 'center', height: Dimensions.get('window').height },
    containerBoxStyles: {
      flex: 1,
      flexDirection: 'row',
      height: Dimensions.get('window').height,
      gap: spacing.xlarge,
      backgroundColor: colors.backgroundLight,
      borderRadius: radius.medium,
      boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    formContainerStyles: {
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      paddingTop: spacing['4xlarge'],
      justifyContent: 'center',
      gap: spacing['5xlarge'],
      margin: spacing.xlarge,
    },
    headerContainerStyles: { alignSelf: 'flex-start', paddingHorizontal: spacing.medium, gap: spacing.xsmall },
    backgroundImageStyles: {
      flex: 1,
      justifyContent: 'center',
      paddingHorizontal: spacing.medium,
      overflow: 'hidden',
      borderTopRightRadius: radius.medium,
      borderBottomRightRadius: radius.medium,
    },
    backgroundContainerStyles: { flexWrap: 'wrap', gap: spacing.xlarge },
    foregroundTextStyles: {
      color: 'white',
      fontSize: typography.fontSize['4xlarge'],
      lineHeight: typography.fontSize['5xlarge'],
      textAlign: 'center',
      alignSelf: 'center',
      fontFamily: typography.fontFamily.bold,
    },
    foregroundImageWrapperStyles: { flex: 1, justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' },
    foregroundImageContainerStyles: { width: '50%' },
    foregroundImageStyles: {
      aspectRatio: 2 / 1,
    },
  });
};

export const getSignInDefaultStyles = ({ breakpoints, spacing, typography }: { breakpoints: IBreakPoints; spacing: ISpacing; typography: ITypography }) => {
  return StyleSheet.create({
    containerStyles: { flexDirection: 'column', flex: 1, width: '100%', maxWidth: breakpoints.medium, margin: 'auto', alignItems: 'center', paddingTop: spacing['4xlarge'] },
    textContainerStyles: { alignItems: 'center', width: '100%', paddingVertical: spacing.medium },
    textColumnStyles: { flexDirection: 'column', alignItems: 'center', paddingHorizontal: spacing.medium },
    textBoldStyles: { fontFamily: typography.fontFamily.semiBold, paddingTop: spacing.large },
    signUpBtnStyles: { justifyContent: 'flex-start' },
    formContainerStyles: { width: '100%' },
  });
};

export const getModalStyles = ({ spacing, radius, colors }: { spacing: ISpacing; radius: IRadius; colors: ISiteColors }) => {
  return StyleSheet.create({
    modalBackgroundStyles: { backgroundColor: 'rgba(0,0,0,0.4)', flex: 1, justifyContent: 'center', alignItems: 'center', position: 'relative' },
    modalStyles: {
      // alignItems: 'center',/
      backgroundColor: colors.backgroundLight,
      borderColor: colors.neutralExtraLight,
      borderRadius: radius.medium,
      borderWidth: 1,
      justifyContent: 'center',
      minHeight: 300,
      margin: 'auto',
      padding: spacing['2xlarge'],
      width: 320,
      maxWidth: Dimensions.get('window').width,
      maxHeight: Dimensions.get('window').height,
    },
    baseStyles: { flex: 1 },
    loadingContainerStyles: { flex: 1, justifyContent: 'center', alignItems: 'center', maxHeight: Dimensions.get('window').height, minHeight: 300 },
    scrollViewStyles: { width: '100%' },
  });
};

export const getMFAStyles = ({ spacing, colors, typography }: { spacing: ISpacing; colors: ISiteColors; typography: ITypography }) => {
  return StyleSheet.create({
    baseStyles: {
      flex: 1,
      flexDirection: 'column',
      marginVertical: spacing.large,
      alignItems: 'center',
      justifyContent: 'space-around',
      gap: spacing.xlarge,
      padding: spacing.small,
    },
    subTitleContainer: { alignItems: 'center', width: '100%', paddingVertical: spacing.medium },
    subTitle: { fontSize: typography.fontSize.medium, textAlign: 'center', lineHeight: typography.fontSize['2xlarge'] },
    inputRowStyles: { flex: 1, flexDirection: 'row', gap: 5, justifyContent: 'space-around', alignItems: 'center', height: '100%', width: '100%' },
    inputContainer: { flex: 1 },
    fieldContainerStyles: { flex: 1 },
    inputContainerStyles: { aspectRatio: 1 / 1, padding: 0.1 },
    customInputStyles: { textAlign: 'center' },
    errorMessageContainer: { padding: spacing['2xlarge'] },
    errorMessage: { color: colors?.danger },
  });
};
