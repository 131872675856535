import React from 'react';

import { ITheme } from '@rugby-au/theme';

import { DefaultMultiForm } from './DefaultMultiForm';
import { SignUpMultiForm } from './SignUpMultiForm';
import { MultiFormConfigProps } from './types';

export const MultiForm = ({
  config,
  layout = 'default',
  appearance = 'primary',
  metaData,
}: {
  config: MultiFormConfigProps;
  layout: 'signup' | 'default';
  appearance: ITheme;
  metaData?: { [key: string]: any };
}) => {
  switch (layout) {
    case 'signup':
      return <SignUpMultiForm config={config} appearance={appearance} metaData={metaData} />;
    default:
      return <DefaultMultiForm config={config} appearance={appearance} metaData={metaData} />;
  }
};

// Multi form component accepts a config with all the steps and their data.
//Steps are as follows,
// 1. set the current step value as 0
// 2. render current step layout
// 3. update back and next button UI and text
// 4. on next click, make sure current page is validated, determine next page, current layout page, then update current step value
// 5. on back click, determine previous page, current layout page, then update current step value
