import React from 'react';
import { Modal, View, ScrollView, ActivityIndicator } from 'react-native';

import { MultiFormLayoutProps } from '@rugby-au/multi-form';
import { signInFormConfig } from './signInFormConfig';
import { useTheme } from '@rugby-au/theme';

import { MultiFactorAuthLayout } from './MultiFactorAuthLayout';
import { getModalStyles } from './styles';
import { useSignInLayout } from './useSignInLayout';
import { SamlForm } from '@rugby-au/saml-form';

export interface SignInProps extends MultiFormLayoutProps {
  config?: any;
  redirectOnLogin?: boolean;
  showSignUp?: true;
  redirectPath?: string;
  header?: string;
  subTextLine1?: string;
  subTextLine2?: string;
  subTextLine3?: string;
  layout?: 'column' | 'default';
  layoutProps?: {
    backgroundImage: string;
    heading: string;
    subImage: string;
  };
}
export const SignInLayout = ({
  config = signInFormConfig,
  redirectOnLogin = true,
  layoutRef,
  triggerNext,
  showSignUp = true,
  redirectPath,
  nextButtonProps,
  header,
  subTextLine1,
  subTextLine2,
  subTextLine3,
  layout,
  layoutProps,
  layoutKey,
}: SignInProps) => {
  const { colors, spacing, radius } = useTheme();

  const { styles } = getModalStyles({ colors, spacing, radius });

  const { content, modalVisible, setModalVisible, onSuccessfulLogin, mfaLayoutRef, rememberMe, isLoading, samlResponse } = useSignInLayout({
    config,
    redirectOnLogin,
    layoutRef,
    triggerNext,
    showSignUp,
    redirectPath,
    nextButtonProps,
    header,
    subTextLine1,
    subTextLine2,
    subTextLine3,
    layout,
    layoutProps,
    layoutKey,
  });

  return (
    <View style={styles.loadingContainerStyles}>
      {samlResponse && <SamlForm target={'_self'} samlResponse={samlResponse} />}
      {isLoading ? (
        <ActivityIndicator color={colors.primary} size={'large'} />
      ) : (
        <View style={styles.baseStyles}>
          {content}
          <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(false);
            }}>
            <View style={styles.modalBackgroundStyles}>
              <View style={styles.modalStyles}>
                <ScrollView style={styles.scrollViewStyles}>
                  <MultiFactorAuthLayout
                    type={'login'}
                    onSuccess={response => {
                      onSuccessfulLogin(response);
                    }}
                    layoutRef={mfaLayoutRef}
                    expirationInMinutes={rememberMe}
                  />
                </ScrollView>
              </View>
            </View>
          </Modal>
        </View>
      )}
    </View>
  );
};
