import StyleSheet from 'react-native-media-query';
import { IColorGroup, ISpacing } from '@rugby-au/theme';

export const getDefaultLayoutStyles = ({ colorTheme, spacing }: { colorTheme: IColorGroup; spacing: ISpacing }) => {
  return StyleSheet.create({
    baseStyles: { height: '100%', minHeight: 300 /*ScreenHeight */ },

    navigateContainer: {
      paddingVertical: spacing.xlarge,
      paddingHorizontal: spacing.xlarge,
      '@media (min-width: 768px)': { paddingVertical: spacing['2xlarge'], paddingHorizontal: spacing['6xlarge'] },
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: colorTheme?.foreground.mid,
    },
    formLayout: { flex: 8 },
    scrollViewContailer: { backgroundColor: 'transparent' },
    formContainer: { flex: 1, justifyContent: 'center' },
  });
};
