import React from 'react';
import { View } from 'react-native';
import { MultiForm } from '@rugby-au/multi-form';
import { signUpMultiFormConfig } from './signUpMultiFormConfig';

export const SignUpLayout = ({}: {}) => {
  return (
    <View style={{ flexDirection: 'column', flex: 1 }}>
      <MultiForm config={signUpMultiFormConfig} layout="default" appearance={'highlight'} />
    </View>
  );
};
