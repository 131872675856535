import React, { MutableRefObject } from 'react';
import { View } from 'react-native';
import { Text } from '@rugby-au/commons';
import { TextInputField } from '@rugby-au/form-fields';
import { Headings } from '@rugby-au/headings';
import { useTheme } from '@rugby-au/theme';
import { Button } from '@rugby-au/button';
import { getMFAStyles } from './styles';
import { useMultiFactorAuth } from './useMultiFactorAuth';

const MFA_CODE_LENGTH = 6;

const typeContent: any = {
  login: {
    title: 'Verify Account',
    subtitle: 'To verify your account, please enter the code sent to your email address or to your mobile.',
    buttonText: 'Sign In',
  },
  'mfa-type-verify': {
    title: 'Verify',
    subtitle: 'To verify your mobile number, please enter the code sent to your mobile.',
    buttonText: 'Submit',
  },
};

export type MFALayoutRefProp = {
  handleMfaVerify: ({}) => void;
};
export const MultiFactorAuthLayout = ({
  type = 'login',
  onSuccess,
  layoutRef,
  expirationInMinutes,
}: {
  type: string;
  onSuccess: (response: any) => void;
  layoutRef: MutableRefObject<MFALayoutRefProp | null>;
  expirationInMinutes: boolean | number;
}) => {
  const { colors, spacing, typography } = useTheme();
  const { fieldRefs, handleMfaVerify, handleResendMfa, loading, errorMessage } = useMultiFactorAuth({ type, onSuccess, layoutRef, expirationInMinutes });

  const { styles } = getMFAStyles({ colors, spacing, typography });

  return (
    <View style={styles.baseStyles}>
      <View>
        <Headings title={typeContent[type].title ?? 'Verify Account'} level={'2'} />
      </View>

      <View style={styles.subTitleContainer}>
        <Text style={styles.subTitle}>{typeContent[type].subtitle}</Text>
      </View>

      <View style={styles.inputRowStyles}>
        {fieldRefs.map((field: any, index: any) => (
          <View key={index} style={styles.inputContainer}>
            <TextInputField
              fieldRef={field.ref}
              fieldContainerStyle={styles.fieldContainerStyles}
              containerStyle={styles.inputContainerStyles}
              customInputStyles={styles.customInputStyles}
              onChangeField={(value: any) => {
                if (value !== '') {
                  const _index = value && value !== '' ? index + 1 : index - 1;
                  if (value.length >= MFA_CODE_LENGTH - 1) {
                    for (let i = 0; i < MFA_CODE_LENGTH; i++) {
                      fieldRefs[i].ref.current?.setValue?.(value[i]);
                      fieldRefs[i].ref.current?.focus?.();
                    }
                    setTimeout(() => {
                      handleMfaVerify({});
                    }, 0);
                  }
                  if (fieldRefs[_index] && fieldRefs[_index]?.ref?.current) {
                    fieldRefs[_index].ref.current?.focus?.();
                  }
                  if (index === MFA_CODE_LENGTH - 1 && value && value !== '') {
                    setTimeout(() => {
                      handleMfaVerify({});
                    }, 0);
                  }
                }
              }}
              onKeyDown={(event: KeyboardEvent) => {
                if (event.key === 'Backspace') {
                  const _index = index - 1;
                  if (fieldRefs[_index] && fieldRefs[_index]?.ref?.current) {
                    fieldRefs[_index].ref.current?.focus?.();
                  }
                } else {
                }
                const value = event.key?.toString();
                fieldRefs[index].ref.current?.setValue?.(value);
              }}
              keyboardType="number-pad"
              autoCompleteTypeWeb="one-time-code"
              textContentType="oneTimeCode"
              type="number"
              maxLength={1}
              {...{
                onPaste: (e: any) => {
                  e.preventDefault();
                  const pasteValue = e.nativeEvent.clipboardData.getData('Text');
                  if (pasteValue.length > MFA_CODE_LENGTH) {
                    return;
                  } else {
                    fieldRefs.forEach((_field: any, _index: any) => {
                      _field.ref.current?.setValue && _field.ref.current?.setValue(pasteValue[_index]);
                    });
                  }
                },
                className: 'mfa-input',
              }}
              mask={(value: string) => {
                const cardValue = value.replace(/\D/g, '').match(/\d/);
                const _value = cardValue?.[0] ?? '';
                return { displayValue: _value, actualValue: _value };
              }}
              pattern="\d*"
            />
          </View>
        ))}
      </View>
      <View>
        <Button
          title={'Resend Code'}
          onPress={async () => {
            handleResendMfa();
          }}
          weight={'underline'}
          appearance="default"
        />
      </View>

      <View>
        <Button
          title={typeContent[type].buttonText ?? 'Submit'}
          onPress={async () => {
            handleMfaVerify({});
          }}
          isLoading={loading}
          size="medium"
        />
      </View>
      <View style={styles.errorMessageContainer}>
        <Text style={styles.errorMessage}>{errorMessage}</Text>
      </View>
    </View>
  );
};
