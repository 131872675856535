import React from 'react';
import { View, ScrollView, TouchableOpacity } from 'react-native';

import { Button } from '@rugby-au/button';
import { ITheme, useTheme } from '@rugby-au/theme';
import { Icons } from '@rugby-au/icons';
import { Text } from '@rugby-au/commons';

import { MultiFormProvider } from './MultiFormProvider';
import { useMultiForm } from './useMultiForm';
import { MultiFormConfigProps } from './types';

export const SignUpMultiForm = ({ config, appearance, metaData }: { config: MultiFormConfigProps; appearance: ITheme; metaData?: { [key: string]: any } }) => {
  const { getLayout, handleNext, handlePrev, currentStepIndex, steps, multiFormData, setMultiFormData } = useMultiForm({ config, metaData, appearance });

  // let ScreenHeight = Dimensions.get('window').height;
  const { colorPairs, spacing } = useTheme();

  return (
    // <View style={{height: ScreenHeight}}>
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1, marginBottom: spacing.large }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: spacing.medium }}>
          {currentStepIndex > 0 ? (
            <TouchableOpacity
              onPress={() => {
                handlePrev();
              }}>
              <Icons type="leftArrow" height={15} width={15} color={'black'} />
            </TouchableOpacity>
          ) : (
            <View />
          )}
          <Text>Skip</Text>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
          {steps.map((item, index) => {
            return (
              <View
                key={item}
                style={{
                  width: '20%',
                  height: spacing.xsmall,
                  backgroundColor:
                    currentStepIndex < index
                      ? colorPairs[appearance]?.neutral.light
                      : currentStepIndex === index
                      ? colorPairs[appearance].background.light
                      : colorPairs[appearance].background.mid,
                }}
              />
            );
          })}
        </View>
        <Text style={{ textAlign: 'center', marginTop: spacing.large }}>
          Steps {currentStepIndex + 1} of {steps.length}
        </Text>
      </View>
      <View style={{ flex: 8 }}>
        <ScrollView>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <MultiFormProvider data={{ multiFormData, setMultiFormData }}>{getLayout()}</MultiFormProvider>
          </View>
        </ScrollView>
      </View>
      <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', borderTopWidth: 1, borderColor: colorPairs[appearance]?.neutral?.light }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginHorizontal: spacing.large }}>
          <Button
            title={'Next'}
            onPress={() => {
              handleNext();
            }}
            borderRadius={10}
          />
        </View>
      </View>
    </View>
    // </View>
  );
};

// Multi form component accepts a config with all the steps and their data.
//Steps are as follows,
// 1. set the current step value as 0
// 2. render current step layout
// 3. update back and next button UI and text
// 4. on next click, make sure current page is validated, determine next page, current layout page, then update current step value
// 5. on back click, determine previous page, current layout page, then update current step value
