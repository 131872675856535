import { MultiFormConfigProps } from '@rugby-au/multi-form/src/types';
import { EmailSentLayout } from './Email';
import { AccountDetailsLayout } from './Account';
import { PasswordLayout } from './Password';

export const signUpMultiFormConfig: MultiFormConfigProps = {
  accountDetails: {
    component: AccountDetailsLayout,
    nextLayout: () => {
      return 'emailSent';
    },
  },
  password: {
    component: PasswordLayout,
    nextLayout: () => {
      return null;
    },
    backButton: { visible: false },
    isEnd: true,
  },
  emailSent: {
    component: EmailSentLayout,
    backButton: { visible: false },
    submitButton: { visible: true, title: 'Take me to the login page' },
    nextLayout: () => {
      return null;
    },
  },
};
