import React from 'react';

import { MultiFormLayoutProps } from '@rugby-au/multi-form';
import { useCheckSignInLayout } from './useCheckSignInLayout';
import { ActivityIndicator } from 'react-native';

export interface CheckSignInProps extends MultiFormLayoutProps {
  redirectOnLogin?: boolean;
}
export const CheckSignInLayout = ({ redirectOnLogin = true, triggerNext, nextButtonProps, layoutKey, layoutRef }: CheckSignInProps) => {
  useCheckSignInLayout({
    redirectOnLogin,
    triggerNext,
    nextButtonProps,
    layoutKey,
    layoutRef,
  });

  return <ActivityIndicator size={'large'} />;
};
