import React from 'react';
import { TouchableOpacity, TouchableOpacityProps } from 'react-native';

import { ITheme, useTheme } from '@rugby-au/theme';
import { CardSize, getCardStyles } from './styles';
export interface CardProps extends TouchableOpacityProps {
  /** Card appearance */
  appearance?: ITheme;
  /**Size */
  size?: CardSize;
  /** Card disabled state */
  disabled?: boolean;
  /** Card onPress function */
  onPress?: () => void;
  /** transparent background */
  transparentBackground?: boolean;
  /** Container Styles */
  containerStyles?: any;
  /** Add a border around the Card */
  addBorder?: boolean;
  /** Children elements */
  children?: React.ReactNode;
}

export const Card = ({
  appearance = 'primary',
  size = 'small',
  onPress,
  disabled = false,
  containerStyles,
  transparentBackground = false,
  addBorder = false,
  children,
}: CardProps) => {
  const { colorPairs, spacing, sizes } = useTheme();

  const styles = getCardStyles({
    colors: colorPairs[appearance] ?? colorPairs.primary,
    disabled,
    spacing,
    transparentBackground,
    size: sizes[size] ?? sizes.large,
    addBorder,
  });

  // console.log(`Card.tsx line 42 - containerStyles `, containerStyles)
  return (
    <TouchableOpacity style={{ ...styles.container, ...containerStyles }} disabled={disabled} onPress={onPress}>
      {children}
    </TouchableOpacity>
  );
};
