import React, { ReactNode, createContext, useContext, useMemo } from 'react';

const MultiFormContext = createContext<{
  data: { multiFormData: { [key: string]: any }; setMultiFormData: (data: any) => { [key: string]: any }; triggerNext: () => void };
}>({
  data: {
    multiFormData: {},
    setMultiFormData: () => {
      return {};
    },
    triggerNext: () => {},
  },
});

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends object ? RecursivePartial<T[P]> : T[P];
};

export const MultiFormProvider = ({ data: providedMultiForm, children }: { data: RecursivePartial<{ [key: string]: any }>; children: ReactNode }) => {
  const value = useMemo(() => ({ data: { ...providedMultiForm } }), [providedMultiForm]);
  return (
    <MultiFormContext.Provider
      // @ts-ignore
      value={value}>
      {children}
    </MultiFormContext.Provider>
  );
};

export const useMultiFormProvider = () => {
  const { data } = useContext(MultiFormContext);

  return data;
};
