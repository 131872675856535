import { Button } from '@rugby-au/button';
import { CardList } from '@rugby-au/cards';
import { Container } from '@rugby-au/container';
import { Form, FormRefMutableProps, FormConfigProps } from '@rugby-au/form';
import { useMultiFormProvider } from '@rugby-au/multi-form';
import { ITheme, useTheme } from '@rugby-au/theme';
import { Text } from '@rugby-au/commons';
import { useAppConfig } from '@rugby-au/app-config';

import React from 'react';
import { ActivityIndicator, Modal, ScrollView, View } from 'react-native';
import { getAccountDetailsStyles } from '../styles';
import { CustomLabel } from '@rugby-au/form-fields/src/Checkbox';

export const AccountDetails = ({
  formRef,
  accountDetailsFormConfig,
  isExistingModalVisible,
  setIsExistingModalVisible,
  isLoading,
  existingUsers,
  cardItemListFooter,
  cardContentListFooter,
  cleanSubmitData,
  onExistingUserPress,
  error,
}: {
  formRef: FormRefMutableProps;
  accountDetailsFormConfig: FormConfigProps;
  isExistingModalVisible: boolean;
  setIsExistingModalVisible: any;
  isLoading: boolean;
  existingUsers: any[];
  cardItemListFooter: any;
  cardContentListFooter: any;
  cleanSubmitData?: (submitData: any, nationalId: any) => any;
  onExistingUserPress: (item: any) => Promise<void>;
  error?: string | null;
}) => {
  const { multiFormData } = useMultiFormProvider();
  const { setNotification } = useAppConfig();
  const { colorPairs } = useTheme();
  const colorTheme = colorPairs[(multiFormData?.__meta?.appearance as ITheme) ?? 'primary'];
  const styles = getAccountDetailsStyles();

  const onFinished = async (result: any) => {
    //if error set notification
    if (result.response.error) {
      setNotification({ message: result.response.error.message, severity: 'error' });
      return { message: 'Error', isValid: false };
    }
    return { message: '', isValid: true };
  };

  return (
    <>
      <Container>
        <View style={{ width: '100%' }}>
          <Form formRef={formRef} formConfig={accountDetailsFormConfig} cleanSubmitData={cleanSubmitData} showOnSubmitError={true} onFinished={onFinished} />
        </View>
      </Container>
      <Modal
        animationType="slide"
        transparent={true}
        visible={isExistingModalVisible}
        onRequestClose={() => {
          setIsExistingModalVisible(!isExistingModalVisible);
        }}>
        <View style={styles.modalBackgroundStyles}>
          <ScrollView>
            <Container>
              <View style={styles.centeredView}>
                <View style={{ ...styles.modalView }}>
                  {isLoading ? (
                    <ActivityIndicator size={'large'} color={colorTheme.background.mid} />
                  ) : (
                    <>
                      {error && <CustomLabel htmlLabel={error} tagsStyles={{ ...styles.errorText }} />}
                      {/* <Text style={styles.modalHeader}>Just checking</Text> */}
                      <Text style={styles.modalText}>
                        Just checking, We’ve found {existingUsers.length} existing profile(s) that might be yours. Select one if it’s you, or simply continue with a new
                        profile.
                      </Text>
                      <CardList onPress={onExistingUserPress} data={existingUsers} cardItemListFooter={cardItemListFooter} cardContentListFooter={cardContentListFooter} />
                      <View style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 10 }}>
                        <Button
                          onPress={() => {
                            setIsExistingModalVisible(false);
                          }}
                          title="Cancel"
                        />
                      </View>
                    </>
                  )}
                </View>
              </View>
            </Container>
          </ScrollView>
        </View>
      </Modal>
    </>
  );
};
