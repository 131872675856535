import React from 'react';
import { View, ViewStyle } from 'react-native';

import { useTheme } from '@rugby-au/theme';
import { Icons } from '@rugby-au/icons';
import { Avatar, AvatarProps } from '@rugby-au/avatar';

import { getCardItemStyles } from './styles';
import { IconTypes } from '@rugby-au/icons/src';
import { CardProps, Card } from './Card';
import { Image, RxImageProps } from '@rugby-au/image';

export interface CardItemProps extends CardProps {
  /** Card onPress function */
  onPress?: () => void;
  /** Size of the card */
  // size: CardSize
  /** Icon */
  iconLeft?: IconTypes;
  /** Icon */
  iconRight?: IconTypes;
  /** Avatar */
  avatarProps?: AvatarProps;
  /** image */
  imageProps?: RxImageProps;
  /** Children Card Content Type */
  children?: React.ReactNode;
}

export const CardItem = ({
  onPress,
  // size = 'large',
  iconRight,
  iconLeft,
  avatarProps,
  imageProps,
  children,
  ...props
}: CardItemProps) => {
  const { colorPairs, spacing } = useTheme();
  // console.log(`GeneralCard.tsx line 40 - imageProps `, imageProps)

  const styles = getCardItemStyles({
    spacing,
    // size: sizes[size] ?? sizes.large,
  });
  const appearance = props?.appearance ? props.appearance : 'primary';
  return (
    <Card onPress={onPress} {...props} containerStyles={{ ...styles.container, ...props.containerStyles }}>
      {iconLeft && <IconContainer icon={iconLeft} containerStyles={styles.icon} color={colorPairs[appearance].background.mid} />}

      {avatarProps && (
        <View style={{ ...styles.avatarContainer }}>
          <Avatar {...avatarProps} />
        </View>
      )}

      {imageProps && (
        <View style={{ ...styles.imageContainer }}>
          <Image width={100} height={100} {...imageProps} />
        </View>
      )}

      {children}
      {iconRight && <IconContainer icon={iconRight} containerStyles={styles.icon} color={colorPairs[appearance].foreground.mid} />}
    </Card>
  );
};

const IconContainer = ({ icon, containerStyles, color }: { icon: IconTypes; containerStyles: ViewStyle; color: string }) => {
  return (
    <View style={containerStyles}>
      <Icons type={icon} color={color} />
    </View>
  );
};
