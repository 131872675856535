import { useAppConfig } from '@rugby-au/app-config';
import { MultiFormLayoutProps } from '@rugby-au/multi-form';
import React, { useEffect } from 'react';
import { EmailSent } from './EmailSent';

//Screen to confirm email sent
export const EmailSentLayout = ({ layoutRef }: MultiFormLayoutProps) => {
  const { setNavigation, setIsLogged } = useAppConfig();

  useEffect(() => {
    const beforeNext = async () => {
      setNavigation && setNavigation('/');
      setIsLogged(true);

      return { isValid: true };
    };

    const beforeBack = async () => {
      return { isValid: true };
    };

    if (layoutRef) {
      layoutRef.current = {
        beforeNext,
        beforeBack,
      };
    }
  }, [layoutRef, setIsLogged, setNavigation]);

  return <EmailSent />;
};
