import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { IColorGroup, ISizeGroup, ISpacing, ITypography } from '@rugby-au/theme';

export const cardSizes = ['small', 'medium', 'large', 'xlarge'] as const;

export type CardSize = typeof cardSizes[number];

export const titleWeightValues = ['regular', 'bold', 'subtle', 'none'] as const;
export const titleSizeValues = ['medium', 'large', 'xlarge'] as const;
export const descriptionSizeValues = ['small', 'medium', 'large'] as const;
export const infoSizeValues = ['small', 'medium', 'large'] as const;

export type TitleWeight = typeof titleWeightValues[number];
export type TitleSize = typeof titleSizeValues[number];
export type DescriptionSize = typeof descriptionSizeValues[number];
export type InfoSize = typeof infoSizeValues[number];

export interface TitleStyles {
  size: ISizeGroup;
  color: string;
  weight: TitleWeight /*typography: ITypography*/;
}
export interface DescriptionStyles {
  size: ISizeGroup;
  color: string /*typography: ITypography*/;
}
export interface InfoStyles {
  size: ISizeGroup;
  color: string /*typography: ITypography*/;
}

export const getCardStyles = ({
  colors,
  disabled,
  transparentBackground,
}: {
  colors: IColorGroup;
  spacing: ISpacing;
  disabled: boolean;
  transparentBackground: boolean;
  size: ISizeGroup;
  addBorder?: boolean;
}) => {
  let baseContainer: ViewStyle | TextStyle = {
    flex: 1,
    //width: size.boxSize,
    // height: size.boxSize,
    flexDirection: 'row',
    //justifyContent: 'center',
    backgroundColor: colors.background.mid,
    color: colors.foreground.mid,
    //borderWidth: spacing.px,
    borderColor: colors.background.dark,
    //...(addBorder && { borderColor: colors[':disabled'].background.mid, borderWidth: spacing.px }),
  };
  if (disabled) {
    baseContainer = {
      ...baseContainer,
      borderColor: disabled ? colors?.[':disabled']?.background?.mid : colors.background?.light,
      backgroundColor: disabled ? colors?.[':disabled']?.background?.mid : colors.background?.light,
      color: disabled ? colors?.[':disabled']?.background?.mid : colors.background?.light,
    };
  }
  if (transparentBackground) {
    baseContainer = { ...baseContainer, borderColor: 'transparent', backgroundColor: 'rgba(255,255,255,0.3)' };
  }
  return StyleSheet.create({
    container: baseContainer,
  });
};

export const getCardItemStyles = ({
  spacing,
}: // size
{
  spacing: ISpacing;
  // size: ISizeGroup;
}) => {
  let baseContainer: ViewStyle | TextStyle = {
    flex: 1,
    //justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: spacing.none,
    paddingLeft: spacing.medium,
    paddingBottom: spacing.none,
    paddingRight: spacing.xlarge,
    backgroundColor: 'rgba(255,255,255,0.3)',
  };
  return StyleSheet.create({
    container: baseContainer,
    avatarContainer: {
      paddingTop: spacing.none,
      paddingLeft: spacing.none,
      paddingBottom: spacing.none,
      paddingRight: spacing.none,
    },
    imageContainer: {
      paddingTop: spacing.none,
      paddingLeft: spacing.none,
      paddingBottom: spacing.none,
      paddingRight: spacing.none,
      // width: size.boxSize,
      // height: size.boxSize,
      // width: size.boxSize,
      // height: size.boxSize,
      // borderRadius: radius.full,
      // backgroundColor: backgroundColor,
      // flex: 1,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      //...(addBorder && { borderColor: colors[':disabled'].background.mid, borderWidth: spacing.px }),
    } as ViewStyle,
    icon: { marginLeft: spacing.medium, width: spacing.medium },
  });
};

export const getCardContentStyles = ({ colors, spacing, headings, typography }: { colors: IColorGroup; spacing: ISpacing; headings: any; typography: ITypography }) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      //justifyContent: 'space-between',
      paddingTop: spacing.small,
      paddingLeft: spacing.xlarge,
      paddingBottom: spacing.small,
      paddingRight: spacing.xlarge,
      backgroundColor: 'transparent',
    },
    headerText: {
      ...headings[4],
      fontFamily: typography.fontFamily.bold,
      marginTop: spacing.xsmall,
      marginBottom: spacing.xsmall,
      color: colors.neutral.dark,
    } as TextStyle,
    subTitle: {
      ...headings[5],
      fontFamily: typography.fontFamily.semiBold,
      marginTop: spacing.xsmall,
      marginBottom: spacing.xsmall,
      color: colors.neutral.dark,
    } as TextStyle,
    info: {
      ...headings[6],
      fontFamily: typography.fontFamily.regular,
      marginTop: spacing.xsmall,
      marginBottom: spacing.xsmall,
      color: colors.neutral.dark,
    } as TextStyle,
  });
};

export const getCardListStyles = ({ colors }: { spacing: ISpacing; colors: IColorGroup }) => {
  return StyleSheet.create({
    list: {
      borderWidth: 1,
      borderColor: colors.background.mid,
      borderRadius: 5,
      // width: '100%'
    },
    listFooter: {},
    overlayLoadingStyles: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
};
