import React, { useMemo } from 'react';
import { View, ImageBackground, Image } from 'react-native';

import { Text } from '@rugby-au/commons';
import { Button } from '@rugby-au/button';
import { Form, FormRefProps } from '@rugby-au/form';
import { useTheme } from '@rugby-au/theme';
import { Headings } from '@rugby-au/headings';

import { getSignInColumnStyles } from './styles';
import { LayoutRefProps } from '@rugby-au/multi-form';

export const SignInColumnLayout = ({
  layoutRef,
  header,
  showSignUp,
  subTextLine1,
  formRef,
  config,
  onFinished,
  getHeaders,
  cleanSubmitData,
  layoutProps,
  onSignIn,
}: {
  layoutRef: React.MutableRefObject<LayoutRefProps> | null;
  header: string;
  showSignUp?: boolean;
  subTextLine1?: string;
  formRef: React.MutableRefObject<FormRefProps>;
  config: any;
  onFinished: (result: any) => Promise<{
    message: string;
    isValid: boolean;
  }>;
  getHeaders: (submitData: any) => Promise<any>;
  cleanSubmitData: (submitData: any) => any;
  layoutProps?: {
    backgroundImage: string;
    heading: string;
    subImage: string;
  };
  onSignIn: () => Promise<void>;
}) => {
  const { colors, breakpoints, spacing, typography, radius } = useTheme();

  const { ids, styles } = getSignInColumnStyles({ breakpoints, spacing, typography, radius, colors });

  const _config = useMemo(() => {
    return {
      ...config,
      meta: {
        ...config.meta,
        submitButton: {
          title: 'Login',
          // appearance: 'neutral-dark',
          fullWidth: true,
          weight: 'bold',
          borderRadius: radius.medium,
          style: { paddingTop: spacing.large, paddingBottom: spacing.large },
        },
      },
    };
  }, [config, radius.medium, spacing.large]);

  return (
    <View style={styles.columnBaseStyles}>
      <View>
        <View style={styles.containerBoxStyles}>
          <View style={styles.formContainerStyles}>
            <View style={styles.headerContainerStyles}>
              <Headings title={layoutRef ? 'Login to continue' : header} level={'2'} />
              {showSignUp && (
                <Text style={{ paddingTop: spacing.large, fontSize: typography.fontSize.large }}>
                  {subTextLine1}{' '}
                  <Button
                    title={'Create a Rugby Xplorer account'}
                    weight={'underline'}
                    onPress={onSignIn}
                    addFromParams={['clientId', 'codeChallenge', 'redirectPath', 'SAMLRequest', 'RelayState']}
                    size="large"
                  />
                </Text>
              )}
            </View>

            <View>
              <Form formRef={formRef} formConfig={_config} onFinished={onFinished} getHeaders={getHeaders} showOnSubmitError={true} cleanSubmitData={cleanSubmitData} />
            </View>
          </View>
          {layoutProps && (
            <View dataSet={{ media: ids.imageColumnStyles }} style={styles.imageColumnStyles}>
              <ImageBackground source={{ uri: layoutProps.backgroundImage }} resizeMode="cover" style={styles.backgroundImageStyles}>
                <View style={styles.backgroundContainerStyles}>
                  <Text style={styles.foregroundTextStyles}>{layoutProps.heading}</Text>
                  <View style={styles.foregroundImageWrapperStyles}>
                    <View style={styles.foregroundImageContainerStyles}>
                      <Image style={styles.foregroundImageStyles} resizeMode={'center'} source={{ uri: layoutProps.subImage }} />
                    </View>
                  </View>
                </View>
              </ImageBackground>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
