import React, { useEffect } from 'react';
import { View } from 'react-native';

import { Button } from '@rugby-au/button';
import { Text } from '@rugby-au/commons';

import { useMultiFormProvider } from '../MultiFormProvider';
import { MultiFormLayoutProps } from '../types';

export const Layout1 = ({ layoutRef, layoutKey }: MultiFormLayoutProps) => {
  const { setMultiFormData } = useMultiFormProvider();

  useEffect(() => {
    const beforeNext = async () => {
      setMultiFormData((prev: any) => {
        return { ...prev, [layoutKey]: 'AddedbeforeNext' };
      });
      console.log('beforeNext', layoutKey);
      return { isValid: true };
    };

    const beforeBack = async () => {
      console.log('beforeBack', layoutKey);
      return { isValid: true };
    };

    if (layoutRef && layoutKey) {
      layoutRef.current = {
        beforeNext,
        beforeBack,
      };
    }
  }, [layoutRef, layoutKey, setMultiFormData]);
  return (
    <View style={{ flex: 1, backgroundColor: '#333' }}>
      <Text>Layout {layoutKey}</Text>
      <View style={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}>
        <Button
          title={'click test'}
          onPress={() => {
            console.log(layoutKey, ' button clicked');
          }}
        />
      </View>
    </View>
  );
};
