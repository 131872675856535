import React from 'react';
import { View } from 'react-native';

import { Text } from '@rugby-au/commons';
import { Button } from '@rugby-au/button';
import { Form, FormRefProps } from '@rugby-au/form';
import { useTheme } from '@rugby-au/theme';
import { Headings } from '@rugby-au/headings';

import { getSignInDefaultStyles } from './styles';
import { LayoutRefProps } from '@rugby-au/multi-form';

export const SignInDefaultLayout = ({
  layoutRef,
  header,
  showSignUp,
  subTextLine1,
  subTextLine2,
  subTextLine3,
  formRef,
  config,
  onFinished,
  getHeaders,
  cleanSubmitData,
  onSignIn,
}: {
  layoutRef: React.MutableRefObject<LayoutRefProps> | null;
  header: string;
  showSignUp?: boolean;
  subTextLine1?: string;
  subTextLine2?: string;
  subTextLine3?: string;
  formRef: React.MutableRefObject<FormRefProps>;
  config: any;
  onFinished: (result: any) => Promise<{
    message: string;
    isValid: boolean;
  }>;
  getHeaders: (submitData: any) => Promise<any>;
  cleanSubmitData: (submitData: any) => any;
  onSignIn: () => Promise<void>;
}) => {
  const { breakpoints, spacing, typography } = useTheme();

  const { styles } = getSignInDefaultStyles({ breakpoints, spacing, typography });

  return (
    <View style={styles.containerStyles}>
      <View>
        <Headings title={layoutRef ? 'Login in to continue' : header} level={'2'} />
      </View>
      {showSignUp && (
        <View style={styles.textContainerStyles}>
          <View style={styles.textColumnStyles}>
            <Text style={styles.textBoldStyles}>{subTextLine1}</Text>
            <Text>
              <Button
                style={styles.signUpBtnStyles}
                title={'Create an account'}
                weight={'underline'}
                onPress={onSignIn}
                addFromParams={['clientId', 'codeChallenge', 'redirectPath', 'SAMLRequest', 'RelayState']}
              />{' '}
              to continue
            </Text>
            <Text style={styles.textBoldStyles}>{subTextLine2}</Text>
            <Text>{subTextLine3}</Text>
          </View>
        </View>
      )}
      <View style={styles.formContainerStyles}>
        <Form formRef={formRef} formConfig={config} onFinished={onFinished} getHeaders={getHeaders} showOnSubmitError={true} cleanSubmitData={cleanSubmitData} />
      </View>
    </View>
  );
};
