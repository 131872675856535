export const forgotPasswordFormConfig: any = {
  meta: {
    name: 'Reset Password',
    title: 'Reset Password',
    submitPath: 'rau/auth/v2/account/reset',
    submitMethod: 'POST',
    submitButton: { title: 'Reset', appearance: 'primary', fullWidth: true, weight: 'bold' },
    // requestParams: { addApiToken: true },
  },
  fields: [
    { key: 'email', label: 'Email', required: true, displayType: 'TextInput', trim: true },
    {
      key: 'type',
      label: 'Send via',
      required: true,
      displayType: 'RadioButtonGroup',
      radioButtonGroup: [
        { radioLabel: 'Email', key: 'email' },
        { radioLabel: 'Sms', key: 'sms' },
      ],
      defaultValue: 'email',
    },
  ],
};
