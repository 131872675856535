import { FormConfigProps } from '@rugby-au/form';

export const signInFormConfig: FormConfigProps = {
  meta: {
    name: 'Login',
    title: '',
    submitPath: 'rau/api/v3/login',
    submitMethod: 'POST',
    submitButton: { title: 'Login', appearance: 'primary', fullWidth: true, weight: 'bold', borderRadius: 8 },
    requestParams: { encodedParams: ['email', 'password'] },
  },
  fields: [
    { key: 'email', label: 'Email', required: true, displayType: 'TextInput', autoCompleteTypeWeb: 'username', trim: true, customInputStyles: { textTransform: 'lowercase' } },
    { key: 'password', label: 'Password', required: true, displayType: 'TextInput', type: 'password', autoCompleteTypeWeb: 'current-password' },
    {
      key: 'rememberMe',
      label: 'Remember me for 30 days',
      align: 'left' as const,
      required: false,
      displayType: 'Checkbox' as const,
      styles: {
        width: '50%',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
      },
    },
    {
      key: 'forgetPassword',
      title: 'Forgot Password',
      appearance: 'default',
      required: false,
      weight: 'underline',
      displayType: 'Button',
      href: '/forgot-password',
      addFromParams: ['clientId', 'codeChallenge', 'redirectPath', 'SAMLRequest', 'RelayState'],
      styles: {
        width: '50%',
        flexWrap: 'wrap',
        alignContent: 'flex-end',
      },
    },
  ],
  nationalConfig: {
    RAU: {},
    USA: {},
  },
};
