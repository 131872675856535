import { AppConfigProps } from '@rugby-au/app-config';
import { Layout1 } from './temp/Layout1';
import { MultiFormConfigProps } from './types';

// giving the next layout option to component makes the component layout unique hence wont be able to use it for other layouts. say profile selection in rego
// having nextLayout logic in the config helps to reuse the component for other layouts but there is an issue going from one layout to another.
export const stepsMap: MultiFormConfigProps = {
  layout1: {
    component: Layout1,
    nextLayout: () => 'layout2',
  },
  layout2: {
    component: Layout1,
    nextLayout: (appConfig: AppConfigProps, multiFormData: { [key: string]: any }) => {
      console.log(appConfig, multiFormData);
      return 'layout4';
    },
  },
  layout3: {
    component: Layout1,
    nextLayout: () => {
      return 'layout4';
    },
  },
  layout4: {
    component: Layout1,
    nextLayout: () => {
      return 'layout4';
    },
    isEnd: true,
  },
};
