import { Form, FormConfigProps, FormRefMutableProps } from '@rugby-au/form';
import { useTheme } from '@rugby-au/theme';
import { Text } from '@rugby-au/commons';

import React from 'react';
import { View } from 'react-native';
import { getPasswordStyles } from '../styles';

export const Password = ({
  formRef,
  formConfig,
  getHeaders,
  error,
}: {
  formRef?: FormRefMutableProps;
  formConfig: FormConfigProps;
  getHeaders: (data: any) => any;
  error: string | null;
}) => {
  const { breakpoints, spacing, colors } = useTheme();
  const styles = getPasswordStyles({ colors });
  return (
    <View style={{ flexDirection: 'column', flex: 1, width: '100%', maxWidth: breakpoints.medium, margin: 'auto', alignItems: 'center', paddingTop: spacing['4xlarge'] }}>
      <View style={{ width: '100%' }}>
        <Form formRef={formRef} formConfig={formConfig} getHeaders={getHeaders} />
        {error && <Text style={styles.error}>{error}</Text>}
      </View>
    </View>
  );
};
