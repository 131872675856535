import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { IColorGroup, IRadius, ISpacing, ITheme, ISizeGroup } from '@rugby-au/theme';

export const avatarSizes = ['small', 'medium', 'large', 'xlarge'] as const;

export type AvatarSize = typeof avatarSizes[number];

type AvatarStyleProps = {
  colors: IColorGroup;
  spacing: ISpacing;
  size: ISizeGroup;
  radius: IRadius;
  backgroundColor: string;
  foregroundColor: string;
  addBorder?: boolean;
  appearance?: ITheme;
};
export const getAvatarStyles = ({ colors, spacing, radius, backgroundColor, foregroundColor, size, addBorder = false }: AvatarStyleProps) => {
  return StyleSheet.create({
    container: {
      width: size.boxSize,
      height: size.boxSize,
      borderRadius: radius.full,
      backgroundColor: backgroundColor,
      // flex: 1,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      ...(addBorder && { borderColor: colors.neutral.mid, borderWidth: spacing.px }),
    } as ViewStyle,
    text: { fontSize: size.fontSizeText, color: foregroundColor } as TextStyle,
    image: { width: '100%', height: '100%' },
  });
};
