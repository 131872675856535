import { useAppConfig } from '@rugby-au/app-config';
import { Button } from '@rugby-au/button';
import { obfuscateEmail } from '@rugby-au/commons';
import { Container } from '@rugby-au/container';
import { Image } from '@rugby-au/image';
import { useMultiFormProvider } from '@rugby-au/multi-form';
import { useTheme } from '@rugby-au/theme';
import { Text } from '@rugby-au/commons';
import { useRouter } from 'next/router';

import React from 'react';
import { View } from 'react-native';
import { getEmailSentStyles } from '../styles';
import { addEncodedParams } from '@rugby-au/services';

//Screen to confirm email sent
export const EmailSent = () => {
  const { multiFormData } = useMultiFormProvider();
  const { setNavigation } = useAppConfig();

  const params = useRouter();
  const { colorPairs, typography, spacing, headings } = useTheme();
  const colors = colorPairs.primary;
  const styles = getEmailSentStyles({ colors, typography, spacing, headings });

  return (
    <Container>
      <View style={{ width: '100%', padding: spacing['4xlarge'] }}>
        <Text style={styles.headerTextStyles}>You've got mail!</Text>
      </View>
      <Image width={100} height={200} resizeMode={'contain'} url={'https://uat-myaccount.rugbyxplorer.com.au/packages/rugby-account/public/images/send.png'} alt={''} />
      <View style={{ width: '100%', paddingTop: spacing.large }}>
        <Text style={styles.messageText}>A sign-in link has been sent to:</Text>
        <Text style={styles.emailText}>{obfuscateEmail(multiFormData?.accountDetails?.submitData?.email)}</Text>
        <Text style={styles.messageText}>Tap the link in the email we just sent you to confirm your email address and we’ll do the rest.</Text>
      </View>
      <View style={{ width: '100%', paddingTop: spacing.large }}>
        <Image width={100} height={200} resizeMode={'contain'} url={'https://uat-myaccount.rugbyxplorer.com.au/packages/rugby-account/public/images/send.png'} alt={''} />
      </View>
      <View style={{ width: '100%', paddingBottom: spacing.large, display: 'flex', alignItems: 'center' }}>
        <View>
          <Button
            onPress={() => {
              setNavigation && setNavigation(addEncodedParams('/login', params.query));
            }}>
            Take me to the login page
          </Button>
        </View>
      </View>
    </Container>
  );
};
