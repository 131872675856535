import React from 'react';
import { View, ScrollView } from 'react-native';

import { Button } from '@rugby-au/button';

import { MultiFormProvider } from './MultiFormProvider';
import { useMultiForm } from './useMultiForm';
import { ITheme, useTheme } from '@rugby-au/theme';
import { MultiFormConfigProps } from './types';
import { getDefaultLayoutStyles } from './styles';

export const DefaultMultiForm = ({ config, appearance, metaData }: { config: MultiFormConfigProps; appearance: ITheme; metaData?: { [key: string]: any } }) => {
  const { getLayout, handleNext, handlePrev, currentStepIndex, steps, multiFormData, setMultiFormData, nextButtonProps, prevButtonProps, isLoading } = useMultiForm({
    config,
    metaData,
    appearance,
  });

  const currentStep = config[steps[currentStepIndex]];
  const { colorPairs, spacing, radius } = useTheme();
  const colorTheme = colorPairs[(multiFormData?.__meta?.appearance as ITheme) ?? 'primary'];

  const { ids, styles } = getDefaultLayoutStyles({ spacing, colorTheme });
  return (
    <View style={styles.baseStyles}>
      {/* <View style={{ flex: 1 }}> */}
      {/* <View style={{ flex: 1 }}>
          <Text>
            MultiForm Header {currentStepIndex + 1}/{steps.length}
          </Text>
        </View> */}
      <View style={styles.formLayout}>
        <ScrollView style={styles.scrollViewContailer}>
          <View style={styles.formContainer}>
            <MultiFormProvider data={{ multiFormData, setMultiFormData }}>{getLayout()}</MultiFormProvider>
          </View>
        </ScrollView>
      </View>
      <View>
        <View style={{ width: `${((currentStepIndex + 1) / steps.length) * 100}%`, backgroundColor: colorPairs[appearance]?.background.mid, height: spacing.xsmall }} />
        <View dataSet={{ media: ids.navigateContainer }} style={styles.navigateContainer}>
          {currentStepIndex > 0 && (!currentStep.backButton || currentStep.backButton.visible) ? (
            <View>
              <Button
                title={'Back'}
                weight={'none'}
                {...prevButtonProps}
                onPress={() => {
                  handlePrev();
                }}
                borderRadius={radius.small}
                size={'large'}
              />
            </View>
          ) : (
            <View />
          )}
          {currentStepIndex < steps.length - 1 && (!currentStep.submitButton || currentStep.submitButton.visible) ? (
            <View>
              {/* {isLoading ? (
                <ActivityIndicator size={'large'} color={colorTheme.background.mid} />
              ) : ( */}
              <Button
                title={currentStep.submitButton ? currentStep.submitButton.title : 'Next'}
                disabled={isLoading}
                {...nextButtonProps}
                onPress={async () => {
                  if (!isLoading) {
                    await handleNext();
                  }
                }}
                borderRadius={radius.small}
                size={'large'}
                isLoading={isLoading}
              />
              {/* )} */}
            </View>
          ) : (
            <View />
          )}
        </View>
      </View>
      {/* </View> */}
    </View>
  );
};

// Multi form component accepts a config with all the steps and their data.
//Steps are as follows,
// 1. set the current step value as 0
// 2. render current step layout
// 3. update back and next button UI and text
// 4. on next click, make sure current page is validated, determine next page, current layout page, then update current step value
// 5. on back click, determine previous page, current layout page, then update current step value
